<template>
  <div
    v-if="getUser.role.slug === 'business-developer-pro' ||
      getUser.role.slug == 'super-administrateur' ||
      getUser.role.slug == 'rh' ||
      getUser.role.slug == 'assistant-rh' ||
      getUser.role.slug == 'formateur'
    "
  >
    <div
      v-if="isPageLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem"
        class="mr-1"
      />
    </div>
    <form-wizard
      v-else
      color="#382DB8"
      :title="null"
      :subtitle="null"
      shape="circle"
      next-button-text="Suivant"
      :finish-button-text="isRegisterEmployeeLoading ? 'Chargement...' : 'Enregistrer'"
      back-button-text="Retour"
      class="mb-3"
      @on-complete="applyRegisterEmployeeAction"
    >
      <!-- account details tab -->
      <tab-content
        title="Identité de l'employé"
        :before-change="validationForm"
      >
        <validation-observer
          ref="accountRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <small class="text-muted font-small-4">
                Renseignez les informations relatives à l'identité de l'employé
              </small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="program ylomi"
                rules="required"
              >
                <b-form-group
                  label="L'employé participe au programme YLOMI ?"
                  label-for="program-ylomi"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="program-ylomi"
                    v-model="payload.is_in_ylomi_program"
                    :reduce="(service) => service.value"
                    label="name"
                    :options="[
                      { value: true, name: 'Oui, il participe' },
                      { value: false, name: 'Non, pas encore' },
                    ]"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="employe de prise de gestion"
                rules="required"
              >
                <b-form-group
                  label="Est un employé de prise de gestion?"
                  label-for="prise-gestion"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="from_customer"
                    v-model="payload.from_customer"
                    :reduce="(service) => service.value"
                    label="name"
                    :options="[
                      { value: 1, name: 'Oui' },
                      { value: 0, name: 'Non' },
                    ]"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Photos de profile de l'employé"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="photo"
                  rules="required"
                >
                  <b-form-file
                    id="wildcard"
                    v-model="payload.profile_image"
                    accept="image/*"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Quartier"
                label-for="address"
              >
                <validation-provider
                  #default="{ errors }"
                  name="quatier"
                  rules="required"
                  autocomplete="off"
                >
                  <b-form-input
                    id="address"
                    v-model="payload.address"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez l'adresse de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="IFU"
                label-for="ifu"
              >
                <validation-provider
                  #default="{ errors }"
                  name="ifu"
                  rules="length:13"
                  autocomplete="off"
                >
                  <b-form-input
                    id="ifu"
                    v-model="payload.ifu"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le numéro ifu de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Prénom & Nom"
                label-for="full-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="prenom & nom"
                  rules="required"
                >
                  <b-form-input
                    id="full-name"
                    v-model="payload.full_name"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le Prénom suivi du Nom de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Numéro de téléphone"
                rules="required"
              >
                <b-form-group
                  label="Numéro de téléphone"
                  label-for="phone"
                >
                  <b-input-group>
                    <b-input-group-prepend
                      is-text
                      :class="errors[0] ? 'border-danger' : ''"
                    >
                      BJ (+229)
                    </b-input-group-prepend>
                    <cleave
                      id="phone"
                      v-model="phone"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Numéro MTN mobile money"
                rules=""
              >
                <b-form-group
                  label="Numéro MTN Mobile Money"
                  label-for="mtn_number"
                >
                  <b-input-group>
                    <b-input-group-prepend :class="errors[0] ? 'border-danger' : ''">
                      <v-select
                        v-model="mtnIndice"
                        :options="phoneOptions"
                        label="indice"
                        :reduce="(item) => item.indice"
                      >
                        <template v-slot:no-options>
                          <b-spinner
                            v-if="isGetIndiceLoading"
                            style="width: 2.5rem; height: 2.5rem"
                            class="align-middle text-info"
                          />
                          <b-button
                            v-else
                            variant="primary"
                            @click="applyAddInitialPhoneNumberAction()"
                          >Ajouter</b-button>
                        </template>
                      </v-select>
                    </b-input-group-prepend>
                    <cleave
                      id="mtn_number"
                      v-model="mtnMobileMoneyNumber"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Numéro Moov Flooz"
                rules=""
              >
                <b-form-group
                  label="Numéro Moov Flooz"
                  label-for="flooz_number"
                >
                  <b-input-group :class="errors[0] ? 'border-danger' : ''">
                    <template #prepend>
                      <v-select
                        v-model="floozIndice"
                        :options="phoneOptions"
                        label="indice"
                        :reduce="(item) => item.indice"
                      >
                        <template v-slot:no-options>
                          <b-spinner
                            v-if="isGetIndiceLoading"
                            style="width: 2.5rem; height: 2.5rem"
                            class="align-middle text-info"
                          />
                          <b-button
                            v-else
                            variant="primary"
                            @click="applyAddInitialPhoneNumberAction()"
                          >Ajouter</b-button>
                        </template>
                      </v-select>
                    </template>
                    <cleave
                      id="flooz_number"
                      v-model="floozMobileMoneyNumber"
                      class="form-control"
                      :class="errors[0] ? 'border-danger' : ''"
                      :raw="false"
                      :options="options.phone"
                      placeholder="12 34 56 78"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Âge"
                label-for="âge"
              >
                <validation-provider
                  #default="{ errors }"
                  name="âge"
                  rules="integer|required"
                >
                  <b-form-input
                    id="âge"
                    v-model="payload.age"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez l'âge de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Situation matrimoniale"
                label-for="marital-status"
              >
                <validation-provider
                  #default="{ errors }"
                  name="situation matrimoniale"
                  rules="required"
                >
                  <b-form-input
                    id="marital-status"
                    v-model="payload.marital_status"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez la situation matrimoniale de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Diplôme / Niveau d'études"
                label-for="degree"
              >
                <validation-provider
                  #default="{ errors }"
                  name="diplôme / niveau d'études"
                  rules="required"
                >
                  <b-form-input
                    id="degree"
                    v-model="payload.degree"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le diplôme ou le niveau d'études de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Nationalité"
                label-for="nationality"
              >
                <validation-provider
                  #default="{ errors }"
                  name="nationalité"
                  rules="required"
                >
                  <b-form-input
                    id="nationality"
                    v-model="payload.nationality"
                    type="text"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez la nationalité de l'employé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Point Focal"
              >
                <b-form-group
                  label="Responsable Point Focal"
                  label-for="point-focal"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="point-focal"
                    v-model="point_focal_id"
                    :reduce="(pointFocal) => pointFocal.id"
                    label="name"
                    :options="pointFocalOption"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="justificatifs"
                rules=""
              >
                <b-form-group
                  label="Justificatifs"
                  label-for="proof_files"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-file
                    v-model="payload.proof_files"
                    accept=".jpg, .png, .gif, .jpeg"
                    placeholder="Choissez les fichiers justificatifs"
                    multiple
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>

      <!-- personal details tab -->
      <tab-content
        title="Service & Photos"
        :before-change="validationFormInfo"
      >
        <validation-observer
          ref="infoRules"
          tag="form"
        >
          <b-row>
            <b-col
              cols="12"
              class="mb-2"
            >
              <small class="text-muted font-small-4">Renseignez les services fournie par cet(tte) employé(e)</small>
            </b-col>
          </b-row>
          <b-row
            v-for="(form, index) in forms"
            :key="index"
          >
            <!-- Requested service -->
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="Service offert"
                rules="required"
              >
                <b-form-group
                  label="Service offert"
                  label-for="services"
                >
                  <v-select
                    id="role"
                    v-model="form.recurring_service_id"
                    :reduce="(service) => service.id"
                    label="name"
                    :options="recurringServicesOption"
                    :state="errors.length > 0 ? false : null"
                  >
                    <template v-slot:no-options>
                      <b-spinner
                        v-if="isServicesRecurringWithoutPaginationLoading"
                        style="width: 2.5rem; height: 2.5rem"
                        class="align-middle text-info"
                      />

                      <span
                        v-else
                        class="mt-1 font-medium-1"
                      >Ce service n'existe pas</span>
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="photos"
                rules=""
              >
                <b-form-group
                  label="Photos"
                  label-for="photos"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-file
                    v-model="form.pictures"
                    accept=".jpg, .png, .gif, .jpeg"
                    placeholder="Choissez les photos de l'employé"
                    multiple
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="{ errors }"
                name="expériences"
                rules="required"
              >
                <b-form-group
                  label="Expériences"
                  label-for="expériences"
                  :state="errors.length > 0 ? false : null"
                >
                  <v-select
                    id="years_of_experience"
                    v-model="form.years_of_experience"
                    :reduce="(range) => range.value"
                    label="value"
                    :options="experiencesRange"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Prétention salariale"
                label-for="salary_pretention"
              >
                <validation-provider
                  #default="{ errors }"
                  name="pretention salariale"
                  rules="required"
                >
                  <b-form-input
                    id="salary_pretention"
                    v-model="form.salary_pretention"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez votre prétention salariale"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="6">
                 <b-form-group
                label="Montant à épargner"
                label-for="montant_epargne"
              >
                <validation-provider
                  #default="{ errors }"
                  name="montant à épargner"
                  rules="required"
                >
                  <b-form-input
                     id="montant_epargne"
                    v-model="form.salary_pretention"
                    type="number"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Entrez le montant à épargner"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                 </b-form-group>
              </b-col> -->
            <b-col md="12">
              <validation-provider
                #default="{ errors }"
                name="compétences"
                rules="required"
              >
                <b-form-group
                  label="Compétences"
                  label-for="compétences"
                  :state="errors.length > 0 ? false : null"
                >
                  <b-form-textarea
                    id="about"
                    v-model="form.about"
                    placeholder="Textarea"
                    rows="5"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row class="mt-4 mb-4">
            <b-col md="3">
              <b-button
                variant="primary"
                :disabled="isRegisterEmployeeLoading"
                @click="repeateAgain"
              >
                Ajouter un autre service
              </b-button>
            </b-col>
            <b-col md="3">
              <b-button
                variant="danger"
                :disabled="forms.length == 1"
                @click="removeItem(index)"
              >
                Supprimer
              </b-button>
            </b-col>
          </b-row>
        </validation-observer>
      </tab-content>
    </form-wizard>

    <!-- Make comment Modal -->
    <b-modal
      id="modal-add-phone-number"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Ajouter initial"
    >
      <validation-observer
        #default="{}"
        ref="orderAssignmentForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModalAddIndice()"
        >
          <b-form-group
            label="Entrez l'indice"
            label-for="indice"
          >
            <validation-provider
              #default="{ errors }"
              name="indice"
              rules="required"
            >
              <b-form-input
                id="indice"
                v-model="payloadIndice.indice"
                type="number"
                :state="errors.length > 0 ? false : null"
                placeholder="Entrez l'indice"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            label="Entrez le réseau"
            label-for="indice"
          >
            <validation-provider
              #default="{ errors }"
              name="mob_net"
              rules="required"
            >
              <v-select
                v-model="payloadIndice.mob_net"
                :options="mobNetOptions"
                label="text"
                :reduce="(item) => item.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isAddIndice"
              variant="primary"
              class="mr-2"
              @click="applyAddIndicesAction()"
            >
              <div v-if="isAddIndice">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Soumettre</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
              @click="hideModalAddIndice()"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BSpinner,
  BButton,
  BFormTextarea,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormInvalidFeedback,
  BFormFile,
  BDropdown,
  BDropdownItem,
  BFormSelect,

} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bj'
import { required, integer, beninPhoneNumberFormatRule } from '@validations'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    ValidationProvider,
    BSpinner,
    vSelect,
    BFormFile,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    ToastificationContent,
    Cleave,
    BDropdown,
    BDropdownItem,
    BFormSelect,
  },

  data() {
    return {
      isGetIndiceLoading: false,
      isAddIndice: false,
      payloadIndice: {
        mob_net: '',
        indice: '',
      },
      mobNetOptions: [{
        value: 'MTN',
        text: 'MTN',
      },
      {
        value: 'MOOV',
        text: 'MOOV',
      },
      ],
      selected: null,
      phoneOptions: [],
      point_focal_id: '',
      forms: [
        {
          recurring_service_id: '',
          about: '',
          pictures: [],
          years_of_experience: '',
          salary_pretention: [],
        },
      ],
      payload: {
        proof_files: [],
        profile_image: '',
        full_name: '',
        address: '',
        recurring_service_id: [],
        about: [],
        is_in_ylomi_program: '',
        from_customer: '',
        pictures: [],
        age: '',
        phone_number: '',
        marital_status: '',
        degree: '',
        nationality: '',
        years_of_experience: [],
        salary_pretention: [],
        mtn_number: '',
        flooz_number: '',
        ifu: '',
      },
      phone: '',

      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'BJ',
        },
      },
      experiencesRange: [
        {
          value: '0 à 1an',
        },
        {
          value: '1 à 3 ans',
        },
        {
          value: '3 à 5 ans',
        },
        {
          value: '5 à 10 ans',
        },
        {
          value: 'Plus de 10 ans',
        },
      ],
      salaryRange: [
        {
          value: 'Entre 30000 & 50000',
        },
        {
          value: 'Entre 50000 & 120000',
        },
        {
          value: 'Entre 120000 & 200000',
        },
        {
          value: '200000 & plus',
        },
      ],
      isPageLoading: true,
      pointFocalOption: [],
      recurringServicesOption: [],
      isRegisterEmployeeLoading: false,
      name: '',
      address: '',
      required,
      integer,
      mtnMobileMoneyNumber: '',
      floozMobileMoneyNumber: '',
      floozIndice: '',
      mtnIndice: '',
      beninPhoneNumberFormatRule,
    }
  },

  computed: {
    ...mapGetters('services', [
      'getRecurringServices',
      'getRecurringServicesWithoutPagination',
    ]),
    ...mapGetters('offerType', ['getPointFocaux']),
    ...mapGetters('auth', ['getUser']),
    ...mapGetters(['isServicesRecurringWithoutPaginationLoading']),
    phoneNumber() {
      return this.phone.replace(/\s/g, '')
    },
    mtn_number() {
      const mtnNum = this.mtnIndice + this.mtnMobileMoneyNumber
      return mtnNum.replace(/\s/g, '')
    },
    flooz_number() {
      const floozNum = this.floozIndice + this.floozMobileMoneyNumber
      return floozNum.replace(/\s/g, '')
    },
  },
  watch: {
    isServicesRecurringWithoutPaginationLoading(val) {
      if (val === false) {
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
      }
    },
  },
  created() {
    this.applyGetPointFocauxAndRecurringServices()
    this.applyGetIndicesAction()
  },
  mounted() {
    if (this.getRecurringServicesWithoutPagination.length != 0) {
      this.getRecurringServicesWithoutPagination.forEach(element => {
        this.recurringServicesOption.push({
          id: element.id,
          name: element.name,
        })
      })
    }

    this.configProAddressField()
  },
  methods: {
    ...mapActions('professionals', ['registerEmployeeAction', 'getEmployeesAction', 'getIndicesAction', 'addIndicesAction']),
    ...mapActions('services', [
      'getRecurringServicesAction',
      'getRecurringServicesWithoutPaginationAction',
    ]),

    ...mapActions('offerType', ['getPointFocalAction']),

    applyGetIndicesAction() {
      this.isGetIndiceLoading = true
      this.getIndicesAction()
        .then(response => {
          this.isGetIndiceLoading = false
          console.log(response)
          response.data.forEach(element => {
            this.phoneOptions.push({
              id: element.id,
              indice: element.indice,
            })
          })
        })
        .catch(error => {
          this.isGetIndiceLoading = false
          console.log(error)
        })
    },
    hideModalAddIndice() {
      this.$bvModal.hide('modal-add-phone-number')
      this.payloadIndice = {
        indice: '',
      }
    },

    applyAddIndicesAction() {
      this.isAddIndice = true
      console.log(this.payloadIndice)
      this.addIndicesAction(this.payloadIndice)
        .then(response => {
          this.isAddIndice = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Succès',
                icon: 'CheckIcon',
                text: response.message,
                variant: 'success',
              },
            },
            {
              position: 'top-center',
            },
          )
          this.hideModalAddIndice()
          this.applyGetIndicesAction()
        })
        .catch(error => {
          this.isAddIndice = false
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
          console.log('Indice Erreur', error)
        })
    },

    applyAddInitialPhoneNumberAction() {
      this.$bvModal.show('modal-add-phone-number')
    },
    applyGetPointFocauxAndRecurringServices() {
      if (this.getPointFocaux != null && this.getRecurringServices.length != 0) {
        this.getPointFocaux.data.forEach(element => {
          this.pointFocalOption.push({
            id: element.id,
            name: element.responsable_name,
          })
        })
        this.getRecurringServicesWithoutPagination.forEach(element => {
          this.recurringServicesOption.push({
            id: element.id,
            name: element.name,
          })
        })
        this.isPageLoading = false
        this.point_focal_id = this.pointFocalOption.find(el => el.name == 'YLOMI').id
      } else {
        this.getPointFocalAction().then(response => {
          response.data.data.forEach(element => {
            this.pointFocalOption.push({
              id: element.id,
              name: element.responsable_name,
            })
          })
          this.point_focal_id = this.pointFocalOption.find(el => el.name == 'YLOMI').id

          this.getRecurringServicesWithoutPaginationAction().then(() => {
            response.data.data.forEach(element => {
              this.recurringServicesOption.push({
                id: element.id,
                name: element.name,
              })
            })
          })
        })
        this.isPageLoading = false
      }
    },
    configProAddressField() {
      const input = document.getElementById('address')
      const options = {
        types: ['establishment'],
        componentRestrictions: { country: ['BJ'] },
      }
      this.autocomplete = new google.maps.places.Autocomplete(input, options)

      this.autocomplete.addListener('place_changed', () => {
        const place = this.autocomplete.getPlace()
        this.payload.address = place.name
      })
    },

    repeateAgain() {
      this.forms.push({
        recurring_service_id: '',
        about: '',
        pictures: [],
        years_of_experience: '',
        salary_pretention: [],
      })
    },

    removeItem(index) {
      this.forms.splice(index, 1)
    },

    applyRegisterEmployeeAction() {
      this.isRegisterEmployeeLoading = true
      this.forms.forEach(element => {
        this.payload.recurring_service_id.push(element.recurring_service_id)
        this.payload.about.push(element.about)
        this.payload.salary_pretention.push(element.salary_pretention)
        this.payload.pictures.push(element.pictures)
        this.payload.years_of_experience.push(element.years_of_experience)
        this.payload.point_focal_id = this.point_focal_id
      })

      console.log('value', this.payload)
      this.payload.phone_number = `229${this.phoneNumber}`
      if (this.mtnMobileMoneyNumber == '') {
        this.payload.mtn_number = ''
      } else {
        const mtnnum = this.mtnIndice + this.mtnMobileMoneyNumber
        this.payload.mtn_number = `229${mtnnum.replace(/\s/g, '')}`
      }
      if (this.floozMobileMoneyNumber == '') {
        this.payload.flooz_number = ''
      } else {
        const flooznum = this.floozIndice + this.floozMobileMoneyNumber
        this.payload.flooz_number = `229${flooznum.replace(/\s/g, '')}`
      }
      this.registerEmployeeAction(this.payload)
        .then(() => {
          this.getEmployeesAction().then(() => {
            this.isRegisterEmployeeLoading = false
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: 'Employé enregistré',
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
            this.$router.push({ name: 'admin-direct-employees' })
            this.payload = {
              proof_files: [],
              profile_image: '',
              full_name: '',
              address: '',
              recurring_service_id: [],
              about: [],
              salary_pretention: [],
              pictures: [],
              age: '',
              phone_number: '',
              marital_status: '',
              degree: '',
              nationality: '',
              from_customer: '',
              mtn_number: '',
              flooz_number: '',
              years_of_experience: [],
              ifu,
            }
          })
        })
        .catch(error => {
          this.isRegisterEmployeeLoading = false
          this.payload.phone_number = ''
          this.payload.recurring_service_id = []
          this.payload.about = []
          this.payload.salary_pretention = []
          this.payload.pictures = []
          this.payload.years_of_experience = []
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'AlertTriangleIcon',
                text: error.response.data.message,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },

    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFormAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

    validationFormSocial() {
      return new Promise((resolve, reject) => {
        this.$refs.socialRules.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

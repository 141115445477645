var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getUser.role.slug === 'business-developer-pro' ||
    _vm.getUser.role.slug == 'super-administrateur' ||
    _vm.getUser.role.slug == 'rh' ||
    _vm.getUser.role.slug == 'assistant-rh' ||
    _vm.getUser.role.slug == 'formateur'
  )?_c('div',[(_vm.isPageLoading)?_c('div',{staticClass:"d-flex justify-content-center mb-1",staticStyle:{"padding-top":"30vh"}},[_c('b-spinner',{staticClass:"mr-1",staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"info"}})],1):_c('form-wizard',{staticClass:"mb-3",attrs:{"color":"#382DB8","title":null,"subtitle":null,"shape":"circle","next-button-text":"Suivant","finish-button-text":_vm.isRegisterEmployeeLoading ? 'Chargement...' : 'Enregistrer',"back-button-text":"Retour"},on:{"on-complete":_vm.applyRegisterEmployeeAction}},[_c('tab-content',{attrs:{"title":"Identité de l'employé","before-change":_vm.validationForm}},[_c('validation-observer',{ref:"accountRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('small',{staticClass:"text-muted font-small-4"},[_vm._v(" Renseignez les informations relatives à l'identité de l'employé ")])]),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"program ylomi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"L'employé participe au programme YLOMI ?","label-for":"program-ylomi","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"program-ylomi","reduce":function (service) { return service.value; },"label":"name","options":[
                    { value: true, name: 'Oui, il participe' },
                    { value: false, name: 'Non, pas encore' } ]},model:{value:(_vm.payload.is_in_ylomi_program),callback:function ($$v) {_vm.$set(_vm.payload, "is_in_ylomi_program", $$v)},expression:"payload.is_in_ylomi_program"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3083316112)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"employe de prise de gestion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Est un employé de prise de gestion?","label-for":"prise-gestion","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"from_customer","reduce":function (service) { return service.value; },"label":"name","options":[
                    { value: 1, name: 'Oui' },
                    { value: 0, name: 'Non' } ]},model:{value:(_vm.payload.from_customer),callback:function ($$v) {_vm.$set(_vm.payload, "from_customer", $$v)},expression:"payload.from_customer"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1796046412)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Photos de profile de l'employé","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"photo","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"wildcard","accept":"image/*"},model:{value:(_vm.payload.profile_image),callback:function ($$v) {_vm.$set(_vm.payload, "profile_image", $$v)},expression:"payload.profile_image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2582525725)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Quartier","label-for":"address"}},[_c('validation-provider',{attrs:{"name":"quatier","rules":"required","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"address","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'adresse de l'employé"},model:{value:(_vm.payload.address),callback:function ($$v) {_vm.$set(_vm.payload, "address", $$v)},expression:"payload.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,468105428)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"IFU","label-for":"ifu"}},[_c('validation-provider',{attrs:{"name":"ifu","rules":"length:13","autocomplete":"off"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"ifu","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez le numéro ifu de l'employé"},model:{value:(_vm.payload.ifu),callback:function ($$v) {_vm.$set(_vm.payload, "ifu", $$v)},expression:"payload.ifu"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3143203335)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prénom & Nom","label-for":"full-name"}},[_c('validation-provider',{attrs:{"name":"prenom & nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"full-name","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez le Prénom suivi du Nom de l'employé"},model:{value:(_vm.payload.full_name),callback:function ($$v) {_vm.$set(_vm.payload, "full_name", $$v)},expression:"payload.full_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1147647033)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro de téléphone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro de téléphone","label-for":"phone"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : '',attrs:{"is-text":""}},[_vm._v(" BJ (+229) ")]),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"placeholder":"12 34 56 78"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,1166344203)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro MTN mobile money","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro MTN Mobile Money","label-for":"mtn_number"}},[_c('b-input-group',[_c('b-input-group-prepend',{class:errors[0] ? 'border-danger' : ''},[_c('v-select',{attrs:{"options":_vm.phoneOptions,"label":"indice","reduce":function (item) { return item.indice; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isGetIndiceLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyAddInitialPhoneNumberAction()}}},[_vm._v("Ajouter")])]},proxy:true}],null,true),model:{value:(_vm.mtnIndice),callback:function ($$v) {_vm.mtnIndice=$$v},expression:"mtnIndice"}})],1),_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"mtn_number","raw":false,"options":_vm.options.phone,"placeholder":"12 34 56 78"},model:{value:(_vm.mtnMobileMoneyNumber),callback:function ($$v) {_vm.mtnMobileMoneyNumber=$$v},expression:"mtnMobileMoneyNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3791279847)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Numéro Moov Flooz","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Numéro Moov Flooz","label-for":"flooz_number"}},[_c('b-input-group',{class:errors[0] ? 'border-danger' : '',scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-select',{attrs:{"options":_vm.phoneOptions,"label":"indice","reduce":function (item) { return item.indice; }},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isGetIndiceLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.applyAddInitialPhoneNumberAction()}}},[_vm._v("Ajouter")])]},proxy:true}],null,true),model:{value:(_vm.floozIndice),callback:function ($$v) {_vm.floozIndice=$$v},expression:"floozIndice"}})]},proxy:true}],null,true)},[_c('cleave',{staticClass:"form-control",class:errors[0] ? 'border-danger' : '',attrs:{"id":"flooz_number","raw":false,"options":_vm.options.phone,"placeholder":"12 34 56 78"},model:{value:(_vm.floozMobileMoneyNumber),callback:function ($$v) {_vm.floozMobileMoneyNumber=$$v},expression:"floozMobileMoneyNumber"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3106416020)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Âge","label-for":"âge"}},[_c('validation-provider',{attrs:{"name":"âge","rules":"integer|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"âge","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'âge de l'employé"},model:{value:(_vm.payload.age),callback:function ($$v) {_vm.$set(_vm.payload, "age", $$v)},expression:"payload.age"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,220425566)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Situation matrimoniale","label-for":"marital-status"}},[_c('validation-provider',{attrs:{"name":"situation matrimoniale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"marital-status","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez la situation matrimoniale de l'employé"},model:{value:(_vm.payload.marital_status),callback:function ($$v) {_vm.$set(_vm.payload, "marital_status", $$v)},expression:"payload.marital_status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,204063295)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Diplôme / Niveau d'études","label-for":"degree"}},[_c('validation-provider',{attrs:{"name":"diplôme / niveau d'études","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"degree","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez le diplôme ou le niveau d'études de l'employé"},model:{value:(_vm.payload.degree),callback:function ($$v) {_vm.$set(_vm.payload, "degree", $$v)},expression:"payload.degree"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1154573254)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Nationalité","label-for":"nationality"}},[_c('validation-provider',{attrs:{"name":"nationalité","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nationality","type":"text","state":errors.length > 0 ? false : null,"placeholder":"Entrez la nationalité de l'employé"},model:{value:(_vm.payload.nationality),callback:function ($$v) {_vm.$set(_vm.payload, "nationality", $$v)},expression:"payload.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,886526191)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Point Focal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Responsable Point Focal","label-for":"point-focal","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"point-focal","reduce":function (pointFocal) { return pointFocal.id; },"label":"name","options":_vm.pointFocalOption},model:{value:(_vm.point_focal_id),callback:function ($$v) {_vm.point_focal_id=$$v},expression:"point_focal_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1330932710)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"justificatifs","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Justificatifs","label-for":"proof_files","state":errors.length > 0 ? false : null}},[_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choissez les fichiers justificatifs","multiple":""},model:{value:(_vm.payload.proof_files),callback:function ($$v) {_vm.$set(_vm.payload, "proof_files", $$v)},expression:"payload.proof_files"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,354395131)})],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Service & Photos","before-change":_vm.validationFormInfo}},[_c('validation-observer',{ref:"infoRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('small',{staticClass:"text-muted font-small-4"},[_vm._v("Renseignez les services fournie par cet(tte) employé(e)")])])],1),_vm._l((_vm.forms),function(form,index){return _c('b-row',{key:index},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Service offert","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Service offert","label-for":"services"}},[_c('v-select',{attrs:{"id":"role","reduce":function (service) { return service.id; },"label":"name","options":_vm.recurringServicesOption,"state":errors.length > 0 ? false : null},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [(_vm.isServicesRecurringWithoutPaginationLoading)?_c('b-spinner',{staticClass:"align-middle text-info",staticStyle:{"width":"2.5rem","height":"2.5rem"}}):_c('span',{staticClass:"mt-1 font-medium-1"},[_vm._v("Ce service n'existe pas")])]},proxy:true}],null,true),model:{value:(form.recurring_service_id),callback:function ($$v) {_vm.$set(form, "recurring_service_id", $$v)},expression:"form.recurring_service_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"photos","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Photos","label-for":"photos","state":errors.length > 0 ? false : null}},[_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif, .jpeg","placeholder":"Choissez les photos de l'employé","multiple":""},model:{value:(form.pictures),callback:function ($$v) {_vm.$set(form, "pictures", $$v)},expression:"form.pictures"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"expériences","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Expériences","label-for":"expériences","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"years_of_experience","reduce":function (range) { return range.value; },"label":"value","options":_vm.experiencesRange},model:{value:(form.years_of_experience),callback:function ($$v) {_vm.$set(form, "years_of_experience", $$v)},expression:"form.years_of_experience"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Prétention salariale","label-for":"salary_pretention"}},[_c('validation-provider',{attrs:{"name":"pretention salariale","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"salary_pretention","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez votre prétention salariale"},model:{value:(form.salary_pretention),callback:function ($$v) {_vm.$set(form, "salary_pretention", $$v)},expression:"form.salary_pretention"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('validation-provider',{attrs:{"name":"compétences","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Compétences","label-for":"compétences","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{attrs:{"id":"about","placeholder":"Textarea","rows":"5"},model:{value:(form.about),callback:function ($$v) {_vm.$set(form, "about", $$v)},expression:"form.about"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1)}),_c('b-row',{staticClass:"mt-4 mb-4"},[_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isRegisterEmployeeLoading},on:{"click":_vm.repeateAgain}},[_vm._v(" Ajouter un autre service ")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-button',{attrs:{"variant":"danger","disabled":_vm.forms.length == 1},on:{"click":function($event){return _vm.removeItem(_vm.index)}}},[_vm._v(" Supprimer ")])],1)],1)],2)],1)],1),_c('b-modal',{attrs:{"id":"modal-add-phone-number","cancel-variant":"outline-secondary","hide-footer":"","centered":"","no-close-on-backdrop":"","no-close-on-esc":"","title":"Ajouter initial"}},[_c('validation-observer',{ref:"orderAssignmentForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('b-form',{staticClass:"p-2",attrs:{"autocomplete":"off"},on:{"reset":function($event){$event.preventDefault();return _vm.hideModalAddIndice()}}},[_c('b-form-group',{attrs:{"label":"Entrez l'indice","label-for":"indice"}},[_c('validation-provider',{attrs:{"name":"indice","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"indice","type":"number","state":errors.length > 0 ? false : null,"placeholder":"Entrez l'indice","rows":"3"},model:{value:(_vm.payloadIndice.indice),callback:function ($$v) {_vm.$set(_vm.payloadIndice, "indice", $$v)},expression:"payloadIndice.indice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Entrez le réseau","label-for":"indice"}},[_c('validation-provider',{attrs:{"name":"mob_net","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.mobNetOptions,"label":"text","reduce":function (item) { return item.value; }},model:{value:(_vm.payloadIndice.mob_net),callback:function ($$v) {_vm.$set(_vm.payloadIndice, "mob_net", $$v)},expression:"payloadIndice.mob_net"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{staticClass:"mr-2",attrs:{"disabled":_vm.isAddIndice,"variant":"primary"},on:{"click":function($event){return _vm.applyAddIndicesAction()}}},[(_vm.isAddIndice)?_c('div',[_c('span',[_vm._v(" Chargement ... ")]),_c('b-spinner',{attrs:{"small":""}})],1):_c('span',[_vm._v("Soumettre")])]),_c('b-button',{attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function($event){return _vm.hideModalAddIndice()}}},[_vm._v(" Annuler ")])],1)],1)]}}],null,false,2199400975)})],1)],1):_c('div',[_c('not-authorized')],1)}
var staticRenderFns = []

export { render, staticRenderFns }